export const ROUTES = {
    bot: {},
    casino: {
        bonuses: {
            providers: {
                edit: {},
            },
        },
        games: {
            available: {},
            compliance: {},
            rounds: {},
            table: {
                duplicate: {},
                edit: {},
                new: {},
            },
            thrilltech: {},
        },
        jackpots: {},
        licences: {
            management: {
                edit: {},
            },
        },
        lobby: {
            categories: {
                edit: {},
            },
            subproviders: {},
        },
        locationBlocking: {},
        maintenance: {},
        other: {},
        payback: {
            edit: {},
            new: {},
        },
        profanities: {},
        providers: {},
        races: {
            edit: {},
            new: {},
        },
        segments: {
            edit: {},
            list: {},
            new: {},
        },
        virtualSports: {
            ticket: {},
        },
    },
    chat: {},
    cms: {
        articles: {
            edit: {},
            templates: {
                edit: {},
                list: {},
            },
        },
        bannerMessages: {
            edit: {},
        },
        blog: {},
        email: {
            content: {
                edit: {},
                new: {},
            },
            logs: {},
            profiles: {
                edit: {},
            },
            send: {},
            templates: {
                edit: {},
            },
            triggers: {
                edit: {},
                new: {},
            },
        },
        generalSettings: {},
        matchPromotion: {},
        mediaGallery: {},
        quiz: {
            details: {},
            templates: {},
        },
        retail: {
            edit: {},
            list: {},
            media: {},
            new: {
                applicationSheet: {},
                bannerSet: {},
                banner: {},
                digitalBoard: {},
                hotCards: {},
                maintenanceImage: {},
                oddsSheet: {},
                oddsView: {},
                registrationImages: {},
                rulesPage: {},
            },
        },
        sms: {
            edit: {},
            new: {},
        },
        snippets: {
            edit: {},
            new: {},
        },
        sportsbookTranslations: {},
        termsAndConditions: {},
        translations: {},
    },
    features: {},
    livebet: {},
    maintain: {
        sportsbook: {},
    },
    marketing: {
        bonuses: {
            duplicate: {},
            edit: {},
            new: {},
        },
        campaigns: {
            edit: {},
            new: {},
            report: {},
        },
        contest: {
            edit: {},
            marketSettings: {},
            new: {},
        },
        crm: {
            analytics: {},
            edit: {},
            new: {},
        },
        eventBasedRewards: {
            edit: {},
            new: {},
        },
        loyalty: {
            edit: {},
            new: {},
        },
        motd: {
            edit: {},
            new: {},
        },
        promotions: {
            all: {},
            edit: {},
            new: {},
            order: {},
        },
        scratchcards: {},
        segments: {
            edit: {},
            new: {},
        },
        tournaments: {
            edit: {},
            new: {},
        },
        upsell: {
            edit: {},
        },
    },
    newBackoffice: {},
    oldBackoffice: {},
    payments: {
        autopayout: {},
        deposits: {},
        exportProviders: {},
        other: {},
        paymentPackages: {},
        providers: {
            balances: {},
            settings: {},
            transactions: {},
        },
        reconciliation: {
            accounting: {},
            operations: {},
        },
        routing: {},
        transactions: {},
        virtualCurrencies: {},
        withdrawals: {},
    },
    poker: {
        blockedCountries: {},
        coolbetOpen: {},
        customCorrections: {},
        maintenance: {},
        manualCorrections: {},
        rake: {},
        tournamentsPlayers: {},
        whitelistedUsers: {},
    },
    racebook: {
        betTypes: {},
        races: {},
        tickets: {
            detail: {},
            list: {},
            resettled: {},
        },
        w2G: {
            event: {},
        },
    },
    reporting: {},
    retail: {
        betShop: {
            addTerminal: {},
            details: {},
            list: {},
            new: {},
        },
        inquiry: {},
        notifications: {},
        otcUsers: {
            list: {},
        },
        permissions: {
            list: {},
        },
        reports: {},
        settings: {},
        terminals: {
            history: {},
            list: {},
            new: {},
        },
        users: {
            list: {},
        },
    },
    security: {
        amlSettings: {},
        closureAppeals: {},
        integrity: {},
        kyc: {
            list: {},
            settings: {},
            uploadLimits: {},
        },
        match: {
            details: {},
            user: {},
        },
        pep: {},
        registryCheck: {},
        responsibleGaming: {
            dashboard: {},
            limitChangeRequests: {},
            limitStatus: {},
            search: {},
        },
        sourceOfWealth: {},
        twoFaSmsSettings: {},
    },
    sportsbook: {
        alarms: {},
        betgeniusMapping: {},
        betradarAlerts: {},
        betradarMapping: {
            leagues: {},
            markets: {},
            matches: {},
            outcomeTypes: {},
            teams: {},
        },
        betradarMarketTypes: {},
        blockedCountries: {},
        categories: {
            defaultFormulaData: {},
            defaultMarkets: {},
            dynamicLimits: {},
            edit: {},
            leagueSeasonData: {},
            matches: {
                edit: {},
                move: {},
                view: {},
            },
            netRisk: {},
            new: {},
            players: {},
            resultedMatches: {},
            resulting: {},
            settings: {},
            teams: {},
            topMarkets: {},
            userLimitRatios: {},
        },
        clients: {
            categories: {},
            languages: {},
            marketTypes: {},
            teams: {},
        },
        comboCard: {
            statistics: {},
            types: {},
        },
        donbestMapping: {
            marketType: {},
        },
        feedUsers: {},
        hotEvents: {},
        limitConfiguration: {},
        manualAcceptanceAggregate: {},
        marketTypeGroups: {},
        marketType: {
            edit: {},
            list: {},
            new: {},
        },
        marketTypesTemplates: {
            edit: {},
            list: {},
        },
        missingResultsV2: {},
        missingResults: {},
        netRisk: {},
        odds: {
            ladders: {
                edit: {},
                list: {},
            },
            pairs: {
                edit: {},
                list: {},
            },
        },
        openbetMapping: {
            marketTypes: {},
            matches: {},
            outcomeTypes: {},
            participants: {},
            sports: {},
        },
        overallRiskV2: {},
        overallRisk: {},
        parlayCard: {
            definition: {
                edit: {},
                new: {},
            },
            edit: {},
            new: {},
            paperTemplates: {},
            payout: {},
            statistics: {},
        },
        players: {
            edit: {},
            list: {},
        },
        refFeedAlerts: {},
        refFeed: {
            categoryConfiguration: {},
            matchMapping: {},
            teamMapping: {},
            unmappedMatches: {},
        },
        regions: {},
        riskGroups: {
            edit: {},
            list: {},
            new: {},
        },
        sameMatchCombo: {},
        simpleBet: {
            matchMapping: {},
            teamMapping: {},
        },
        smallReports: {
            betPartialClawbacks: {},
            offtheboardMatches: {},
            suspendedRisks: {},
        },
        smartResulting: {
            edit: {},
        },
        sportsOrder: {},
        sports: {},
        statistics: {
            liveMatches: {},
            pendingBets: {},
        },
        teams: {
            edit: {},
            list: {},
        },
        teaser: {
            categoryCombination: {},
            payout: {},
        },
        ticketList: {},
        ticketsFreeze: {},
        tickets: {
            detail: {},
            pendingUnresulted: {},
        },
        tools: {
            fixtureProvider: {},
            pick: {},
        },
        topCategories: {},
        trading: {},
        turnover: {},
        w2G: {
            event: {},
            list: {},
        },
        winlist: {},
    },
    users: {
        alias: {},
        create: {},
        customKeycloakLogs: {},
        customKeycloak: {},
        details: {},
        list: {},
        loginHistory: {},
        raf: {
            settings: {},
        },
    },
    warehouse: {
        delivery: {
            errors: {},
        },
        extract: {
            errors: {},
        },
        status: {},
    },
};

export const oldRoutes = {
    // SPORTSBOOK
    tickets: 'ticketlist',
    // CASINO
    casino: 'casino',
    // USERS
    users: 'users',
    'login-history': 'login-history',
    'refer-a-friend': 'raf/list',
    // CMS
    blog: 'blog/',
    translations: 'translations',
    emails: 'emails/',
    articles: 'articles/',
    media: 'media/',
    // BONUSES
    bonuses: 'bonuses',
    'new-bonuses': 'bonuses/new',
    scratchcards: 'scratchcards',
    'scratchcards-report': 'scratchcards-report',
    snippets: 'snippets/',
    tournaments: 'tournaments/list',
    segments: 'segments',
    campaigns: 'campaigns',
    // PAYMENTS
    transactions: 'transactions',
    'failed-deposits': 'failed-deposits',
};

export const newRoutes = {
    //marketing
    'match-of-the-day': 'marketing/bonuses/match-of-the-day',
    'refer-a-friend': 'users/refer-a-friend/',
    'providers-balances': 'payments/psp-balances',
    'reconciliation-accounting': 'payments/reconciliation/accounting',
    'sb-campaigns': 'marketing/campaigns',
    'terms-and-conditions': 'cms/terms-and-conditions',
    translations: 'cms/translations',
};
