import {
    getServiceUrl,
    httpDelete,
    httpGet,
    httpPatch,
    httpPost,
    httpPut,
    parseAPIErrorMessage,
} from '../services/api';
import { logger } from '@sentry/utils';
import { notification } from 'antd';
import {
    BetRadarAlert,
    BetradarMarket,
    DeleteMarketTypeBody,
    EditMarketTypeBody,
    GetBetradarMatchesResponse,
    GetCompetitorsFilter,
    GetCompetitorsResponse,
    GetMarketFilter,
    GetMarketTypeFilter,
    GetMarketTypesResponse,
    GetMatchesFilter,
    GetOutcomeTypesFilter,
    GetOutcomeTypesResponse,
    GetTournamentsFilter,
    GetTournamentsResponse,
    MapCompetitor,
    MapMarketType,
    MapOutcomeType,
    MapTournamentRequest,
    UnmapMarketType,
    UnmapMatch,
    UnmapOutcomeType,
} from '../types/betradar';
import { DatabaseMarketOddsSource } from '@staycool/sports-types';

export const getUrl = url => getServiceUrl('betradar', url);

export async function getBetRadarAlerts() {
    try {
        const url = getUrl('alerts');
        return await httpGet<BetRadarAlert[]>(url);
    } catch (error) {
        logger.error('BetradarMicroservice', 'getBetRadarAlerts', error);
        notification.error({
            message: 'Error while fetching BetRadar alerts',
        });
        return [];
    }
}

export async function getBetRadarCompetitors(filters: GetCompetitorsFilter) {
    try {
        const url = getUrl('competitor');
        return await httpPost<GetCompetitorsResponse>(url, filters);
    } catch (error) {
        logger.error('BetradarMicroservice', 'getBetRadarCompetitors', error);
        notification.error({
            message: "Error while fetching BetRadar's unmapped teams",
        });
        return { total: 0, competitors: [] };
    }
}

export async function mapBetRadarTeam(data: MapCompetitor) {
    try {
        const url = getUrl('competitor/map');
        return await httpPost(url, data);
    } catch (error) {
        logger.error('BetradarMicroservice', 'mapBetRadarTeam', error);
        notification.error({
            message: "Error while mapping BetRadar's team",
            description: parseAPIErrorMessage(error),
        });
    }
}

export async function unmapBetRadarTeam(id: number) {
    const url = getUrl('competitor/unmap');
    return await httpPost(url, { id });
}

export async function getBetradarMarketOddsSource(marketId) {
    try {
        const url = getUrl(`market-odds-source/${marketId}`);
        return await httpGet<Partial<DatabaseMarketOddsSource>>(url);
    } catch (error) {
        logger.log('BetradarMicroservice', 'getBetradarMarketOddsSource', error);
        notification.error({ message: 'Error getting Betradar odds source for the market' });
    }
}

export async function resolveBetRadarAlert(alertId: number) {
    const url = getUrl(`alerts/${alertId}/resolve`);
    return await httpPost<BetRadarAlert[]>(url);
}

export async function addComment(alertId: number, comment: string) {
    const url = getUrl(`alerts/${alertId}`);
    return await httpPatch<BetRadarAlert>(url, { comment });
}

export async function getBetRadarMatches(filters: GetMatchesFilter) {
    try {
        const url = getUrl('match/all');
        return await httpPost<GetBetradarMatchesResponse>(url, filters);
    } catch (error) {
        logger.error('BetradarMicroservice', 'getBetRadarMatches', error);
        notification.error({
            message: "Error while fetching BetRadar's matches",
        });
        return { total: 0, matches: [] };
    }
}

interface BetradarMapMatchPayload {
    id: string;
    coreId: number;
    coreCategoryId: number;
}

export async function mapBetRadarMatch(data: BetradarMapMatchPayload) {
    try {
        const url = getUrl('match/map');
        return await httpPost(url, data);
    } catch (error) {
        logger.error('BetradarMicroservice', 'mapBetRadarMatch', error);
        if (error.code === 4000) {
            notification.error({
                message: error.message,
            });
        } else {
            notification.error({
                message: "Error while mapping BetRadar's match",
            });
        }
    }
}

export async function unmapBetRadarMatch(data: UnmapMatch) {
    try {
        const url = getUrl('match/unmap');
        return await httpPost(url, data);
    } catch (error) {
        logger.error('BetradarMicroservice', 'unmapBetRadarMatch', error);
        if (error.code === 4000) {
            notification.error({
                message: error.message,
            });
        } else {
            notification.error({
                message: "Error while unmapping BetRadar's match",
            });
        }
    }
}

export async function getBetRadarMarkets(filter: GetMarketFilter) {
    try {
        const url = getUrl('market');
        return await httpPost<{ total: number; markets: BetradarMarket[] }>(url, filter);
    } catch (error) {
        logger.error('BetradarMicroservice', 'getBetRadarMarkets', error);
        notification.error({
            message: "Error while fetching BetRadar's markets",
        });
        return { total: 0, markets: [] };
    }
}

export async function mapBetRadarMarketType(params: MapMarketType) {
    try {
        const url = getUrl('market-type/map');
        await httpPost(url, params);
    } catch (error) {
        logger.error('BetradarMicroservice', 'mapBetRadarMarketType', error);
        notification.error({
            message: "Error while mapping BetRadar's market type",
            description: parseAPIErrorMessage(error),
        });
    }
}

export async function unmapBetRadarMarketType(params: UnmapMarketType) {
    try {
        const url = getUrl('market-type/unmap');
        await httpPost(url, params);
    } catch (error) {
        logger.error('BetradarMicroservice', 'unmapBetRadarMarketType', error);
        notification.error({
            message: "Error while unmapping BetRadar's market type",
            description: parseAPIErrorMessage(error),
        });
    }
}

export async function getBetRadarMarketTypes(filter: GetMarketTypeFilter) {
    try {
        const url = getUrl('market-type');
        return await httpPost<GetMarketTypesResponse>(url, filter);
    } catch (error) {
        logger.error('BetradarMicroservice', 'getBetRadarMarketTypes', error);
        notification.error({
            message: "Error while fetching BetRadar's market types",
        });
        return { total: 0, marketTypes: [] };
    }
}

export async function editBetradarMarketType(payload: EditMarketTypeBody) {
    try {
        const url = getUrl('market-type');
        await httpPut(url, payload);
    } catch (error) {
        logger.error('BetradarMicroservice', 'editBetradarMarketType', error);
        notification.error({
            message: "Error while editing BetRadar's market type",
            description: parseAPIErrorMessage(error),
        });
    }
}

export async function cloneBetradarMarketType(payload: EditMarketTypeBody) {
    try {
        const url = getUrl('market-type/clone');
        await httpPost(url, payload);
    } catch (error) {
        logger.error('BetradarMicroservice', 'cloneBetradarMarketType', error);
        notification.error({
            message: "Error while cloning BetRadar's market type",
            description: parseAPIErrorMessage(error),
        });
    }
}

export async function deleteBetradarMarketType(payload: DeleteMarketTypeBody) {
    try {
        const url = getUrl('market-type');
        await httpDelete(url, payload);
    } catch (error) {
        logger.error('BetradarMicroservice', 'deleteBetradarMarketType', error);
        notification.error({
            message: "Error while deleting BetRadar's market type",
            description: parseAPIErrorMessage(error),
        });
    }
}

export async function getBetRadarOutcomeTypes(filter: GetOutcomeTypesFilter) {
    try {
        const url = getUrl('outcome-type/all');
        return await httpPost<GetOutcomeTypesResponse>(url, filter);
    } catch (error) {
        logger.error('BetradarMicroservice', 'getBetRadarOutcomeTypes', error);
        notification.error({
            message: "Error while fetching BetRadar's outcome types",
        });
        return { total: 0, outcomeTypes: [] };
    }
}

export async function mapBetRadarOutcomeType(params: MapOutcomeType) {
    try {
        const url = getUrl('outcome-type/map');
        await httpPost(url, params);
    } catch (error) {
        logger.error('BetradarMicroservice', 'mapBetRadarOutcomeType', error);
        notification.error({
            message: "Error while mapping BetRadar's outcome type",
        });
    }
}

export async function unmapBetRadarOutcomeType(params: UnmapOutcomeType) {
    try {
        const url = getUrl('outcome-type/unmap');
        await httpPost(url, params);
    } catch (error) {
        logger.error('BetradarMicroservice', 'unmapBetRadarOutcomeType', error);
        notification.error({
            message: "Error while unmapping BetRadar's outcome type",
        });
    }
}

export async function getBetradarLeagues(params: GetTournamentsFilter) {
    try {
        const url = getUrl('tournament');
        return await httpPost<GetTournamentsResponse>(url, params);
    } catch (error) {
        logger.error('BetradarMicroservice', 'getBetradarLeagues', error);
        notification.error({
            message: "Error while getting BetRadar's leagues",
        });
    }
}

export async function mapBetradarLeague(params: MapTournamentRequest) {
    const url = getUrl('tournament/map');
    return await httpPost(url, params);
}

export async function unmapBetradarLeague(id: string) {
    const url = getUrl('tournament/unmap');
    return await httpPost(url, { id });
}
